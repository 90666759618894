$circle-size: 80%;
$blending: hard-light;

@keyframes moveInCircle {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveVertical {
  0% {
    transform: translateY(-50%);
  }
  50% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(-50%);
  }
}

@keyframes moveHorizontal {
  0% {
    transform: translateX(-50%) translateY(-10%);
  }
  50% {
    transform: translateX(50%) translateY(10%);
  }
  100% {
    transform: translateX(-50%) translateY(-10%);
  }
}

.gradient-bg {
  width: 100%;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: -1000;

  svg {
    display: none;
  }

  .gradients-container {
    opacity: 0.25;
    filter: url(#goo);
    width: 100%;
    height: 100%;
    @media (max-width: $breakpoint-md) {
      width: 400%;
    }
  }

  .g1 {
    position: absolute;
    background: radial-gradient(
        circle at center,
        rgba($gradient_color1, 0.8) 0,
        rgba($gradient_color1, 0) 30%
      )
      no-repeat;
    mix-blend-mode: $blending;

    width: $circle-size;
    height: $circle-size;
    top: calc(30% - $circle-size / 2);
    left: calc(50% - $circle-size / 2);

    transform-origin: center center;
    animation: moveVertical 90s linear infinite;

    opacity: 1;
  }

  .g2 {
    position: absolute;
    background: radial-gradient(
        circle at center,
        rgba($gradient_color2, 0.8) 0,
        rgba($gradient_color2, 0) 30%
      )
      no-repeat;
    mix-blend-mode: $blending;

    width: calc($circle-size + 20%);
    height: calc($circle-size + 20%);
    top: calc(50% - $circle-size / 2);
    left: calc(90% - $circle-size / 2);

    transform-origin: calc(50% - 300px);
    animation: moveInCircle 90s linear infinite;
    animation-direction: reverse;

    opacity: 1;
  }

  .g3 {
    position: absolute;
    background: radial-gradient(
        circle at center,
        rgba($gradient_color3, 0.8) 0,
        rgba($gradient_color3, 0) 30%
      )
      no-repeat;
    mix-blend-mode: $blending;

    width: calc($circle-size + 200px);
    height: calc($circle-size + 200px);
    top: calc(20% - $circle-size / 2 + 200px);
    left: calc(50% - $circle-size / 2 - 500px);

    transform-origin: calc(50% + 100px);
    animation: moveInCircle 100s linear infinite;

    opacity: 1;
  }

  .g4 {
    position: absolute;
    background: radial-gradient(
        circle at center,
        rgba($gradient_color5, 0.8) 0,
        rgba($gradient_color5, 0) 30%
      )
      no-repeat;
    mix-blend-mode: $blending;

    width: $circle-size;
    height: $circle-size;
    top: calc(70% - $circle-size / 2);
    left: calc(50% - $circle-size / 2);

    transform-origin: calc(40% - 200px);
    animation: moveHorizontal 120s ease infinite;

    opacity: 0.7;
  }
}
