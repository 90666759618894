.tk-image {
  img {
    width: 100%;
    height: auto;
  }
}

.tk-image__container {
  position: relative;
  margin: 0 80px;
  @media (max-width: $breakpoint-md) {
    margin: 0;
    margin-bottom: 20px;
  }
}

.tk-image__caption {
  font-size: 10px;
  color: $darker-white;
  position: absolute;
  bottom: 0;
  z-index: 2;
  margin: 0.5em;
  line-height: normal;

  @media (max-width: $breakpoint-md) {
    color: $grey;
    display: block;
    position: relative;
  }
}
