@import "fonts";
@import "variables";
@import "typo";

html {
  font-family: "Amiamie", Verdana, Arial, sans-serif;
  font-weight: 200;
  font-size: 20px;
  color: $grey;
}

body {
  margin: 0;
  overscroll-behavior: none;
  &.mobile-menu-toggled {
    @media (max-width: 900px) {
      max-height: 100vh;
      overflow: hidden;
    }
  }

  height: 100vh;
  width: 100%;
}

.tk-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.tk-content {
  position: relative;
  width: 100%;
  max-width: 1200px;
  @media (min-width: 1200px) {
    border-left: $grid-border;
    border-right: $grid-border;
  }
  margin: 0 auto;
  padding-bottom: 200px;
  flex: 1;
  @media (max-width: $breakpoint-md) {
    border-left: none;
    border-right: none;
    margin-top: 150px;
    padding-top: 30px;
    padding-bottom: 0;
  }
}
@import "layout/layout";
@import "navigation/navigation";
@import "components/components";
@import "formular/formular";
