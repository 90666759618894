@import "newsletter";

.tk-form--error,
.tk-form--success {
  font-size: 0.8rem;
  font-family: "Amiamie", Verdana, Arial, sans-serif;
}

.tk-form--error {
  color: $gradient_color2;
}

.tk-form--success {
  color: $gradient_color3;
}
