.tk-image-teaser {
  display: flex;
  min-height: 250px;
  img {
    height: 100%;
    object-fit: cover;
    background: rgba($gradient_color2, 0.5);
  }
  h3 {
    font-size: 2rem;
    line-height: 2.4rem;
    @media (max-width: $breakpoint-md) {
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }
  .tk-image-teaser--content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    @media (max-width: $breakpoint-md) {
      padding: 0 30px;
    }
  }
  .tk-image-teaser--image {
    padding: 0;
    border-right: $grid-border;
    border-left: none;
    img {
      width: 100%;
      height: 100%;
    }
    @media (max-width: $breakpoint-md) {
      padding: 0 30px;
      margin-bottom: 30px;
      border: none;
    }
  }
  .flex-row-reverse {
    .tk-image-teaser--image {
      border-left: $grid-border;
      border-right: none;
      @media (max-width: $breakpoint-md) {
        border: none;
      }
    }
  }
}

.tk-image-teaser__container {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;

  @media (max-width: $breakpoint-md) {
    width: auto;
    height: auto;
  }
}

.tk-image-teaser__caption {
  font-size: 10px;
  color: $darker-white;
  position: absolute;
  bottom: 0;
  z-index: 2;
  margin: 0.5em;
  line-height: normal;

  @media (max-width: $breakpoint-md) {
    color: $grey;
    display: block;
    position: relative;
  }
}
