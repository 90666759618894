.tk-footer__container {
  display: flex;
  flex-direction: column;
  background-color: #202020;
  justify-content: space-between;
  color: white;
  border-top: $grid-border;

  @media (max-width: $breakpoint-md) {
    margin-top: 50px;
  }

  .tk-footer__newsletter-container {
    padding: 0;
    background: $gradient-light;
  }

  .tk-footer__newsletter {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    .tk-footer__newsletter--right,
    .tk-footer__newsletter--left {
      padding: 80px 20px;
      @media (max-width: $breakpoint-md) {
        padding: 30px;
      }
    }
    .tk-footer__newsletter--right {
      @media (max-width: $breakpoint-md) {
        padding-top: 0;
      }
    }
    .tk-form--error,
    .tk-form--success {
      color: white;
    }
  }

  .tk-footer__newsletter--left {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    img {
      width: 100%;
      max-width: 300px;
    }

    @media (max-width: $breakpoint-md) {
      justify-content: flex-start;
    }
  }

  .tk-footer__newsletter--right {
    border-left: $grid-border;
    @media (max-width: $breakpoint-md) {
      border-left: none;
      .row {
        > div {
          padding: 0;
        }
      }
    }
  }

  .tk-footer__newsletter-form {
    display: flex;
    width: 50%;
    align-items: center;
    margin: 16px;
  }

  .tk-footer {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    @media (max-width: $breakpoint-md) {
      flex-direction: column;
      align-items: center;
      padding: 20px 0 80px 0;
    }
    .tk-link__container {
      display: flex;
      align-items: center;
      padding: 20px;
      height: 100%;
      .tk-link {
        color: white;
        padding: 0;
      }
      @media (max-width: $breakpoint-md) {
        padding: 5px;
      }
    }
    .tk-footer__social,
    .tk-footer__menu {
      display: flex;
      @media (max-width: $breakpoint-md) {
        flex-direction: column;
        align-items: center;
      }
    }
    .tk-footer__social {
      @media (max-width: $breakpoint-md) {
        margin-bottom: 40px;
      }
    }
  }

  .tk-footer__links {
    display: flex;
  }
  .tk-footer__link {
    font-family: "DINdong", Verdana, Arial, sans-serif;
    font-size: 16px;
    position: relative;
    text-decoration: none;
    color: white;
    margin: 20px;
    font-weight: 300;
    line-height: 2rem;
  }

  .tk-language-toggle {
    padding: 5px;
    @media (min-width: $breakpoint-md) {
      padding: 20px;
    }
  }
}
