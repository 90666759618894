.tk-text__content {
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    text-align: left;
  }
  &--right {
    border-left: $grid-border;
  }
  &--left {
    border-right: $grid-border;
  }
  div {
    padding: 40px;
  }
  @media (max-width: $breakpoint-md) {
    border: none;
    div {
      padding: 0 30px;
    }
  }
  @media (min-width: $breakpoint-md) {
    :last-child {
      margin: 0;
    }
  }
}
