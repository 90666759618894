@import "../variables.scss";

.tk-wrapper-input-label {
  display: flex;
  margin: 5px 0;
  .tk-input {
    min-width: 16px;
    min-height: 16px;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .input-checkbox {
      border: #000000 solid 2px;
      position: absolute;
      background-color: white;
      display: inline-block;
      vertical-align: middle;
      appearance: none;
      width: 16px;
      height: 16px;

      &:checked ~ .checkmark {
        &::before,
        &::after {
          position: absolute;
          content: "";
          width: 20px;
          height: 1.5px;
          background-color: #000000;
        }

        &::before {
          transform: translate(-10%, 500%) rotate(45deg);
        }

        &::after {
          transform: translate(-10%, 500%) rotate(135deg);
        }
      }
    }

    .checkmark {
      display: inline-block;
      width: 16px;
      height: 16px;
      position: absolute;
    }
  }
}
