.tk-team {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 40px;
  @media (max-width: $breakpoint-md) {
    padding: 0 30px;
  }
  .tk-member {
    width: 100%;
    margin-bottom: 40px;
    @media (min-width: $breakpoint-sm) {
      width: 40%;
      margin-bottom: 0;
    }
    @media (min-width: $breakpoint-lg) {
      width: 25%;
    }
    img {
      width: 100%;
      height: auto;
      aspect-ratio: 1/1;
      object-fit: cover;
      margin-bottom: 20px;
    }
    h3,
    p {
      text-align: center;
      margin-bottom: 0;
    }
  }
}
