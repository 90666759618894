.tk-select {
    align-content: center;
    select {
        font-family: "DINdong", Verdana, Arial, sans-serif;
        padding: 3px 3px;
        background-color: #202020;
        color: white;
        outline: white;
        &:active,
        &:hover {
            background: $gradient;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            border: 1px solid;
            border-image: $gradient;
            border-image-slice: 1;
          }
    }
  
}