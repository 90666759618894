.tk-article {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  @media (max-width: $breakpoint-md) {
    div {
      border-bottom: none;
    }
  }
  .tk-image-teaser {
    p {
      @media (max-width: $breakpoint-md) {
        margin-bottom: 1rem;
        font-style: italic;
      }
    }
  }
}

.tk-article-meta {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  font-size: 1.2rem;
  width: 300px;
}

.no-border {
  border: none;

  .tk-text__content--left {
    border: none;
  }

  .tk-text__content--right {
    border: none;
  }
}
