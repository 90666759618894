.tk-navbar__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: fixed;
  z-index: 10;
  border-bottom: $grid-border;
  background-color: #ffffff;

  &:not(.sticky) {
    position: relative;
  }

  @media (min-width: $breakpoint-md) {
    .tk-navbar {
      height: 100px;
    }
    &:not(.sticky) {
      .tk-navbar {
        height: 200px;
      }
    }
  }
  .tk-navbar__logo {
    &--large {
      opacity: 0;
    }
    &--small {
      opacity: 1;
    }
  }
  &:not(.sticky) {
    .tk-navbar__logo {
      &--large {
        opacity: 1;
      }
      &--small {
        opacity: 0;
      }
    }
  }
  .tk-navbar {
    width: 100%;
    display: flex;
    max-width: 1200px;
    justify-content: space-between;
    @media (min-width: 1200px) {
      border-left: $grid-border;
      border-right: $grid-border;
    }
    transition: height 0.3s ease;
    @media (max-width: $breakpoint-md) {
      border-left: none;
      border-right: none;
    }

    .tk-navbar__logo {
      display: flex;
      align-items: center;
      border-right: $grid-border;
      width: 300px;
      height: 100%;
      position: relative;
      @media (max-width: $breakpoint-md) {
        width: 100%;
      }
      &--large,
      &--small {
        position: absolute;
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        background-size: 80%;
        @media (min-width: $breakpoint-lg) {
          background-size: 90%;
        }
      }
      &--large {
        @media (max-width: $breakpoint-md) {
          display: none;
        }
      }
      &--small {
        @media (max-width: $breakpoint-md) {
          opacity: 1;
        }
      }
    }
    .tk-navbar--left {
      display: flex;
      @media (max-width: $breakpoint-md) {
        flex: 1;
      }
    }
    .tk-navbar--right {
      display: flex;
      flex: 1;
      align-self: start;
      height: 100px;
      border-bottom: $grid-border;

      @media (max-width: 900px) {
        width: 100px;
        height: 100%;
      }
      .tk-navbar__menu {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        @media (max-width: 1200px) {
          flex-direction: column-reverse;
          .tk-navbar__slogan {
            padding-left: 24px;
            flex: 1;
            p {
              font-size: 0.6rem;
            }
          }
        }
        @media (max-width: 900px) {
          display: none;
        }
      }
      @media (max-width: 900px) {
        border-bottom: none;
        justify-content: flex-end;
        align-self: stretch;
      }
      .tk-navbar__links {
        display: flex;
        .tk-navbar__link {
          display: flex;
          align-items: center;
          min-height: 60px;
          padding: 0 24px;
          border-left: $grid-border;
          a {
            padding: 0;
          }
        }
        @media (max-width: 1200px) {
          border-bottom: $grid-border;
          .tk-navbar__link:nth-child(1) {
            border-left: none;
          }
        }
      }
      .tk-navbar__slogan {
        display: flex;
        flex: 1;
        align-items: center;
        padding-right: 50px;
        padding-left: 20px;
        p {
          margin: 0;
          font-size: 0.8rem;
        }
      }
      .tk-navbar__toggle {
        display: none;
        align-items: center;
        border-left: $grid-border;
        .tk-navbar__toggle-icon {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: relative;
          width: 100px;
          height: 80px;
          padding: 24px;
          cursor: pointer;
          .tk-navbar__toggle-stroke {
            background-color: #202020;
            width: 50px;
            height: 2px;
            transition: all 0.2s ease;
          }
        }
        @media (max-width: 900px) {
          display: flex;
        }
      }
    }
  }

  &.sticky {
    .tk-navbar {
      .tk-navbar--right {
        border-bottom: none;
      }
      @media (max-width: 900px) {
        .tk-navbar--right {
          border: none;
        }
      }
    }
  }
  &.mobile--toggled {
    height: 100vh;
    .tk-navbar {
      position: relative;
      border-bottom: $grid-border;
    }
    .tk-navbar__menu--mobile {
      display: none;
      flex: 1;
      @media (max-width: 900px) {
        display: flex;
      }
    }
    .tk-navbar__toggle-stroke {
      &:nth-child(2),
      &:nth-child(3) {
        position: absolute;
        top: 40px;
      }
      &:nth-child(2) {
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
      }
      &:nth-child(1),
      &:nth-child(4) {
        display: none;
      }
    }
  }
  .tk-navbar__menu--mobile {
    overflow-y: scroll;
    background-repeat: no-repeat;
    background-position: right top;
    background-size: auto 100%;
    background-image: url("../../../assets/images/background/fade_1.png");
    background-color: white;
    .tk-navbar__menu {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .tk-navbar__slogan {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 40px 20px;
      border-bottom: $grid-border;
      p {
        font-family: "DINdong", Verdana, Arial, sans-serif;
        font-size: 2rem;
        line-height: 2.25rem;
        text-align: center;
        @media (max-width: 700px) {
          font-size: 1rem;
          line-height: 1.8rem;
        }
      }
    }
    .tk-navbar__links {
      padding-bottom: 20px;
      .tk-navbar__link {
        border-bottom: $grid-border;
        text-align: center;
        padding: 24px;
      }
    }
  }
}
