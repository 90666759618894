.tk-quote-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  @media (max-width: $breakpoint-md) {
    padding: 20px;
  }
  .tk-quote {
    font-family: "DINdong", Verdana, Arial sans-serif;
    font-size: 1.5rem;
    text-decoration: none;
    color: $gradient;
    padding-top: 8px;
    text-align: center;
    width: 50%;

    &--author {
      background: $gradient;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      white-space: pre-line;
    }
    @media (max-width: $breakpoint-md) {
      width: 100%;
    }
  }
}
