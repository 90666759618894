.accordion-container {
  width: 100%;

  .accordion-headline {
    border-bottom: $grid-border;
    @media (max-width: $breakpoint-md) {
      padding-bottom: 40px;
    }
  }
  @media (max-width: $breakpoint-md) {
    border-bottom: $grid-border;
    margin-bottom: 50px;
  }
  .accordion {
    --bs-accordion-border-color: none;
    border: none;
    width: 100%;
    @media (min-width: $breakpoint-md) {
      border-left: $grid-border;
    }
    .accordion-item {
      width: 100%;
      padding: 0;
      padding-top: 24px;
      padding-bottom: 24px;
      background: transparent;
      &:not(:last-child) {
        border-bottom: $grid-border;
      }

      .accordion-header {
        border: none !important;
        .accordion-button {
          padding: 0 40px;
          @media (max-width: $breakpoint-md) {
            padding: 0 30px;
          }
        }
      }

      .accordion-button {
        background-color: transparent;
        display: inline-grid;
        grid-template-columns: 80% 20%;

        h3 {
          margin: 0;
        }

        &:focus {
          border-color: none;
          box-shadow: none;
        }
      }
    }
    .accordion-body {
      margin-top: 32px;
      padding: 0 40px;
      @media (max-width: $breakpoint-md) {
        padding: 0 30px;
      }
    }
  }
  .accordion-fillblock {
    border-right: $grid-border;

    @media (max-width: $breakpoint-sm) {
      border-bottom: transparent;
    }
  }
}
