.tk-accessibility {
  display: flex;
  align-items: center;
  border-bottom: 2px solid $grey;
  @media (max-width: $breakpoint-md) {
    border-bottom: none;
  }
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    border-right: 2px solid $grey;
    img {
      width: 90px;
    }
    @media (max-width: $breakpoint-md) {
      border-right: none;
      padding: 20px;
      img {
        width: 50px;
        height: 50px;
      }
    }
  }
  &__description {
    padding: 70px;
    display: flex;
    align-self: center;
    p {
      font-family: "DINdong", Verdana, Arial, sans-serif;
    }
    @media (max-width: $breakpoint-md) {
      padding: 20px;
    }
  }
}
