$amiamie-font-path: "../fonts/Amiamie";
$dindong-font-path: "../fonts/DINdong";
$glyphWorld-font-path: "../fonts/GlyphWorld";

@font-face {
  src: url("#{$amiamie-font-path}/Amiamie-Regular.ttf") format("truetype");

  font-family: "Amiamie";
  font-weight: 400;
  font-display: optional;
}

@font-face {
  src: url("#{$amiamie-font-path}/Amiamie-Light.ttf") format("truetype");

  font-family: "Amiamie";
  font-weight: 300;
}

@font-face {
  src: url("#{$amiamie-font-path}/Amiamie-Black.ttf") format("truetype");

  font-family: "Amiamie";
  font-weight: 700;
}

@font-face {
  src: url("#{$dindong-font-path}/DINdong-Regular.ttf") format("truetype");

  font-family: "DINdong";
  font-weight: 400;
}

@font-face {
  src: url("#{$glyphWorld-font-path}/GlyphWorld-AnimalSoul.woff2")
    format("woff2");

  font-family: "AnimalSoul";
  font-weight: 400;
}

@font-face {
  src: url("#{$glyphWorld-font-path}/GlyphWorld-Desert.woff2") format("woff2");

  font-family: "Desert";
  font-weight: 400;
}

@font-face {
  src: url("#{$glyphWorld-font-path}/GlyphWorld-Meadow.woff2") format("woff2");

  font-family: "Meadow";
  font-weight: 400;
}

@font-face {
  src: url("#{$glyphWorld-font-path}/GlyphWorld-Mountain.woff2") format("woff2");

  font-family: "Mountain";
  font-weight: 400;
}

@font-face {
  src: url("#{$glyphWorld-font-path}/GlyphWorld-WasteLand.woff2")
    format("woff2");

  font-family: "WasteLand";
  font-weight: 400;
}
