$white: #ffffff;
$darker-white: #faf8fd;
$grey: #202020;

$gradient_color1: #fe8900;
$gradient_color2: #ff4777;
$gradient_color3: #4aa8ff;
$gradient_color4: #ffc178;
$gradient_color5: #f185a1;
$gradient_color6: #8fc5f7;

$gradient: var(
  --Gradient,
  linear-gradient(
    90deg,
    $gradient_color1 0%,
    $gradient_color2 50.52%,
    $gradient_color3 100%
  )
);
$gradient-light: var(
  --Gradient-light,
  linear-gradient(
    114deg,
    $gradient_color4 5.96%,
    $gradient_color5 51.98%,
    $gradient_color6 97.05%
  )
);

$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xl: 1400px;

$grid-border: 2px solid $grey;
