@import "./fonts.scss";

.animalsoul {
    font-family: "AnimalSoul";
}

.desert {
    font-family: "Desert";
}

.meadow {
    font-family: "Meadow";
}

.mountain {
    font-family: "Mountain";
}

.wasteland {
    font-family: "WasteLand";
}