.tk-map-teaser {
  display: flex;
  .tk-map-headline--mobile {
    @media (max-width: $breakpoint-md) {
      padding: 0 30px;
    }
  }
  @media (max-width: $breakpoint-md) {
    margin-bottom: 50px;
  }
  .tk-map-teaser--content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    @media (max-width: $breakpoint-md) {
      padding: 0 30px;
    }
  }
  .tk-map-teaser--map {
    padding: 0;
    border-right: $grid-border;
    border-left: none;
    max-height: 300px;
    @media (max-width: $breakpoint-md) {
      margin-bottom: 30px;
      border: none;
    }
    .tk-map {
      height: 100%;
      @media (max-width: $breakpoint-md) {
        padding: 0 30px;
      }

      .leaflet-container {
        z-index: 1;
      }
      .leaflet-tile-pane {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
      }
    }
  }
  .flex-row-reverse {
    .tk-map-teaser--image {
      border-left: $grid-border;
      border-right: none;
      @media (max-width: $breakpoint-md) {
        border: none;
      }
    }
  }
}
