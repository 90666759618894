.tk-mediaplayer__container {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  container-type: inline-size;
  min-width: 270px;

  p,
  label {
    margin: 0;
    text-align: center;
    color: $white;
  }

  .tk-mediaplayer__link {
    margin: 0;
    text-align: center;
    color: $white;
    padding-bottom: 10px;
  }

  img {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.tk-mediaplayer__reactplayer {
  position: absolute;
  top: 0;
  left: 0;
}

.tk-mediaplayer__overlay-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tk-mediaplayer__overlay {
  display: flex;
  background: rgba($grey, 0.85);
  flex-direction: column;
  padding: 50px;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 60%;
}

.tk-mediaplayer__wrapper {
  &--S {
    width: 50%;

    @media (max-width: $breakpoint-md) {
      width: 100%;
      margin: auto 30px;
    }
  }

  &--M {
    width: 75%;

    @media (max-width: $breakpoint-md) {
      width: 100%;
      margin: auto 30px;
    }
  }

  &--L {
    width: 100%;
  }
}

@container (max-width: 670px) {
  .tk-mediaplayer__overlay {
    width: 80%;
    height: 80%;
    padding: 10px;
  }
}

@container (max-width: 500px) {
  .tk-mediaplayer__overlay {
    width: 100%;
    height: 100%;
    padding: 10px;
  }

  .tk-mediaplayer__caption {
    color: transparent !important;
  }
}

@container (max-width: 400px) {
  p,
  label {
    font-size: small;
  }

  .tk-mediaplayer__link {
    font-size: small;
    padding-bottom: 0;
  }
}

.tk-mediaplayer__caption {
  font-size: 10px;
  width: 100%;
  color: $darker-white;
  text-align: left !important;
  margin: 0.5em !important;
  position: absolute;
  bottom: 0;
  z-index: 2;
  line-height: normal;
}
