.tk-newsletter {
  padding: 30px;

  p,
  label {
    color: $grey;
  }

  .tk-newsletter-submit {
    display: flex;
    align-items: center;
    align-self: flex-start;
    height: 65px;
    @media (max-width: $breakpoint-md) {
      padding: 0;
    }
  }
  @media (max-width: $breakpoint-md) {
    padding: 0 30px;
  }
}

.tk-newsletter-container {
  display: flex;
  border-left: $grid-border;
  @media (max-width: $breakpoint-md) {
    border-left: none;
    padding: 0;
  }
}
