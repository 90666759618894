.tk-event__mediaplayer-container {
  margin: 0;
  padding: 0;
}

.tk-event__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: $grid-border;
  padding: 50px;
  @media (max-width: $breakpoint-md) {
    border-right: none;
    padding: 30px;
  }

  h1 {
    margin: 0;
    text-align: left;
    width: 100%;
  }

  .highlighted {
    background: $gradient;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.tk-event__title-date-place-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  padding: 30px;

  @media (max-width: $breakpoint-md) {
    align-items: flex-start;
    padding: 0 30px;
    align-items: flex-start;
  }
}

.tk-event__info-container {
  padding: 30px 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 20px;

  @media (max-width: $breakpoint-md) {
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 60px;
    row-gap: 12px;
    padding: 30px;
    border-top: $grid-border;
    border-bottom: $grid-border;
  }
}

.tk-event__description-container {
  padding: 40px;
  border-left: $grid-border;
  @media (max-width: $breakpoint-md) {
    padding: 0 30px;
    border-left: none;
  }
}

h5 {
  margin: 0;
  min-width: 100px;
  text-align: center;

  @media (max-width: $breakpoint-md) {
    text-align: left;
  }
}

.tk-events--empty {
  width: 100%;
  border-bottom: $grid-border;
  p {
    margin-bottom: 0;
  }
  padding: 40px;
  @media (max-width: $breakpoint-md) {
    padding: 0 40px 40px 40px;
    margin-bottom: 40px;
  }
}
