.tk-button {
  display: inline-block;
  text-decoration: none;
  font-family: "DINdong", Verdana, Arial, sans-serif;
  padding: 10px 20px;
  font-weight: 300;
  border: 2px solid black;
  text-align: center;

  cursor: pointer;

  &.large {
    padding: 10px 120px;
    @media (max-width: $breakpoint-md) {
      padding: 10px;
    }
  }
  &--primary {
    background-color: black;
    color: white;

    &:active,
    &:hover {
      background: $gradient-light;
      color: black;
      border: 2px solid black;
    }
  }

  &--secondary {
    background-color: white;
    color: black;

    &:active,
    &:hover {
      background: $gradient;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      border: 2px solid;
      border-image: $gradient;
      border-image-slice: 1;
    }
  }
  @media (max-width: $breakpoint-md) {
    width: 100%;
  }
}

.tk-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: $grid-border;
  border-left: none;
  @media (max-width: $breakpoint-md) {
    border-right: none;
    border-left: none;
    .tk-button {
      width: 100%;
      text-align: center;
    }
  }

  @media (min-width: $breakpoint-md) {
    div {
      padding: 80px 0;
    }
  }
  @media (max-width: $breakpoint-md) {
    div {
      width: 100%;
      padding: 0 30px;
    }
  }
  .flex-row-reverse & {
    border-left: $grid-border;
    border-right: none;
    @media (max-width: $breakpoint-md) {
      border-right: none;
      border-left: none;
    }
  }
}
