.tk-not-found {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  &__buttons {
    :nth-child(1) {
      margin-right: 20px;

      @media (max-width: $breakpoint-lg) {
        margin-bottom: 20px;
        margin-right: 0;
      }
    }
  }
  @media (min-width: $breakpoint-md) {
    padding-top: 100px;
  }
}
