.carousel_container {
  .carousel_nav_container {
    padding: 15px 0;
    .control-button-container {
      display: flex;
      justify-content: space-between;
      button {
        border: none;
        width: 47%;
        height: 45px;
        background-color: black;
        svg {
          fill: white;
          max-height: 50%;
        }
      }
    }
  }
  .carousel-content {
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
    display: flex;
    flex-direction: row;
    overflow: scroll;
    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
    .slide-container {
      height: 400px;
      .current-slide {
        transform: translate(-100%, 0%);
      }
      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
