.tk-event-teaser {
  h3 {
    font-size: 2rem;
    line-height: 2.4rem;
    @media (max-width: $breakpoint-md) {
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }
  &-archive {
    display: flex;
    justify-content: flex-start;
    @media (max-width: $breakpoint-sm) {
      flex-direction: column;
      align-items: center;
    }
    .tk-event-teaser__container {
      width: 100%;
      @media (min-width: $breakpoint-sm) {
        width: 50%;
      }
      @media (min-width: $breakpoint-lg) {
        width: calc(100% / 3);
      }
      h3 {
        font-size: 1.4rem;
        line-height: 1.8rem;
      }
      .tk-event-teaser__producer,
      .tk-event-teaser__date {
        font-size: 1rem;
        line-height: 1.4rem;
      }
    }
    .tk-event-teaser__wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      @media (min-width: $breakpoint-sm) {
        padding: 10px;
      }
      @media (min-width: $breakpoint-md) {
        padding: 20px;
      }
      @media (min-width: $breakpoint-lg) {
        padding: 40px 20px;
      }
      .buttons {
        padding: 0 30px;
      }
    }
    .tk-event-teaser {
      .tk-event-teaser__poster {
        @media (max-width: $breakpoint-sm) {
          padding: 0 30px;
        }
        &-container {
          position: relative;
          width: 100%;
        }
      }
    }
    .content-wrapper {
      display: block;
      height: 100%;
    }
    .tk-event-teaser__poster {
      display: block;
      height: auto;
    }
  }
  &__poster {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: $breakpoint-sm) {
      height: auto;
    }
    img {
      width: 100%;
      height: auto;
      box-shadow: 5px 5px 5px 0px rgba($grey, 0.3);
      background: rgba($gradient_color1, 0.5);
    }
    @media (max-width: $breakpoint-md) {
      padding: 0 30px;
      justify-content: flex-start;
      img {
        width: 100%;
      }
    }
    @media (min-width: $breakpoint-md) {
      padding: 40px 0;
    }

    &-container {
      position: relative;
      width: 70%;
      @media (max-width: $breakpoint-lg) {
        width: 80%;
      }
      @media (max-width: $breakpoint-md) {
        width: 100%;
      }
    }
    &-caption {
      font-size: 10px;
      color: $darker-white;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 2;
      margin: 0.5em;
      line-height: normal;

      @media (max-width: $breakpoint-sm) {
        color: $grey;
        display: block;
        position: relative;
      }
    }
  }
  &__information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    border-left: $grid-border;
    padding: 50px;
    @media (max-width: $breakpoint-md) {
      padding: 0 30px;
      border-left: none;
    }
    .col {
      padding: 0;
    }
    .flex-row-reverse & {
      border-right: $grid-border;
      border-left: none;
    }
    &--text {
      @media (max-width: $breakpoint-sm) {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  &--small {
    .tk-event-teaser__poster {
      padding: 0 30px;
      padding-bottom: 30px;
      justify-content: flex-start;
      img {
        width: 100%;
      }
    }
    .tk-event-teaser__information {
      padding: 0 30px;
      border-left: none;
      border-right: none;
    }
  }
  &--focus {
    .tk-event-teaser__information {
      padding: 80px 120px;
      @media (max-width: $breakpoint-lg) {
        padding: 80px;
      }
      @media (max-width: $breakpoint-md) {
        padding: 0 30px;
      }
    }
  }
  &__title {
    margin: 0;
  }
  &__date {
    margin: 0;
    font-size: 2rem;
    font-family: "DINdong", Verdana, Arial, sans-serif;
    @media (max-width: $breakpoint-lg) {
      font-size: 1.4rem;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 1rem;
    }
  }
  &__producer {
    margin: 0;
    font-size: 1.4rem;
    font-family: "DINdong", Verdana, Arial, sans-serif;
    @media (max-width: $breakpoint-lg) {
      font-size: 1rem;
    }
  }
  &--small {
    .tk-event-teaser__producer {
      @media (max-width: $breakpoint-lg) {
        font-size: 1rem;
      }
    }
  }
  &__location {
    p {
      margin: 0;
    }
  }
  &__time {
    p {
      margin: 0;
    }
  }
  &__icons {
    display: flex;
    flex-direction: "column";
  }
  &__location,
  &__icons,
  &__time {
    @media (max-width: $breakpoint-md) {
      display: none;
    }
  }
  .tk-event-heading--mobile {
    padding: 0 30px;
  }
}
