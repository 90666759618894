.tk-input-text{
    color:black;
    input{
        width: 100%;
        background-color: white;
        border: black solid 2px;
        height: 65px;
        padding: 10px;
        &:focus{
            outline-style: solid;
            outline-color:  white;
            outline-width: medium;
        }
    }
    label{
        font-family: "DINdong", Verdana, Arial, sans-serif;
        font-size: 24px;
    }
}