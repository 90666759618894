h1 {
  font-family: "DINdong", Verdana, Arial, sans-serif;
  font-size: 3rem;
  line-height: 3.4rem;
  font-weight: 400;
  letter-spacing: -1.4px;
  @media (max-width: $breakpoint-md) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

h2 {
  font-family: "DINdong", Verdana, Arial, sans-serif;
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 400;
  @media (max-width: $breakpoint-md) {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}

h3 {
  font-family: "DINdong", Verdana, Arial, sans-serif;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 400;
}

h4 {
  font-family: "Amiamie-Light", Verdana, Arial, sans-serif;
  font-size: 1.4rem;
  line-height: 2rem;
}

h5 {
  font-family: "DINdong", Verdana, Arial, sans-serif;
  font-size: 1.4rem;
  line-height: 2rem;
}

h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0;
}

p {
  font-family: "Amiamie", Verdana, Arial, sans-serif;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.6rem;
}

a.tk-link {
  font-family: "DINdong", Verdana, Arial sans-serif;
  font-size: 1rem;
  position: relative;
  text-decoration: none;
  color: $grey;
  padding-top: 5px;

  &.active,
  &:hover {
    background: $gradient;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &:hover {
    cursor: pointer;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: $gradient;
      animation: stretch 0.2s ease;
    }
  }

  @keyframes stretch {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
}
